import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Loading from '../components/common/Loading';
import { useAuth } from '../hooks/useAuth';
import { useHasPermission } from '../hooks/usePermissions';

const AuthorizedRoute = ({ component: Component, path, location, permissionModule: Module, permissionAction: Action, ...rest }) => {
  const { isAuthenticated, user, ready } = useAuth();

  const render = props => <Component {...props} />;

  const isAuthorizedForRoute = useHasPermission(Module, Action);
  if (!ready) {
    return <Loading />;
  }

  return isAuthenticated ? (
    (!Module && !Action) || isAuthorizedForRoute? (
      <Route path={path} location={location} render={render} {...rest} />
    ) : (
      <Redirect
        to={{
          pathname: '/access-denied'
        }}
      />
    )
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        search:
          path !== '/'
            ? `?returnUrl=${location.pathname}${location.search}`
            : ''
      }}
    />
  );
};

export default AuthorizedRoute;
