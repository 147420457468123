import React from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import { usePage } from '../../hooks/usePage';
import Menu from './Menu';
import { canReadOrganizations, canReadAdministrators, canReadOrgAdministrators } from '../../hooks/usePermissions';
import config from '../../config';

const Header = ({ location }) => {
  const {
    title,
    parentTitle,
    parentLink,
    headerVisible,
    menuVisible,
    currentOrganizationId
  } = usePage();

  const showSuperAdminMenu = pathname => {
    return (
      pathname && (
      pathname === '/organizations' ||
      pathname === '/admins' || pathname === '/organization-types' ||
      pathname.endsWith('/dashboard'))
    );
  };

  const showOrganizationAdminMenu = pathname => {
    return (
      pathname && pathname.startsWith('/organizations/') && !pathname.includes('/reports/')
    );
  };

  const showOrganizationReportsMenu = pathname => {
    return ( 
      pathname && pathname.includes('/reports/')
    );
  };

  return (
    <div className='header w-full relative'>
      {headerVisible && (
        <>
        <div className='flex'>
          {canReadOrganizations() && canReadAdministrators() && showSuperAdminMenu(location.pathname) && (
                <div className='flex items-center justify-center flex-wrap w-full'>
                  <Link to='/organizations' className='btn btn--primary-menu mr-3'>
                    Organizations
                  </Link>
                  <Link to='/admins' className='btn btn--primary-menu mr-3'>
                    Admins
                  </Link>
                </div>
          )}
          {currentOrganizationId && !showSuperAdminMenu(location.pathname) && showOrganizationAdminMenu(location.pathname) && (
                <div className='flex items-center justify-center flex-wrap w-full'>
                  { canReadOrgAdministrators() && (
                  <Link to={`/organizations/${currentOrganizationId}/admins`} className='btn btn--primary-menu mr-3'>
                    Admins
                  </Link>)
                  }
                  <Link to={`/organizations/${currentOrganizationId}/participants`} className='btn btn--primary-menu mr-3'>
                    Participants
                  </Link>
                  <Link to={`/organizations/${currentOrganizationId}/reports/JourneyProgress`} className='btn btn--primary-menu mr-3'>
                    Reports
                  </Link>
                  <a href={config.resourcesUrl} className='btn btn--primary-menu mr-3' target='_blank'>
                    Resources
                  </a>
                  <Link to={`/organizations/${currentOrganizationId}/dashboard`} className='btn btn--primary-menu mr-3'>
                    Zoë Central
                  </Link>
                </div>
          )}
          {currentOrganizationId && showOrganizationReportsMenu(location.pathname) && (
                <div className='flex items-center justify-center flex-wrap w-full'>
                  <NavLink to={`/organizations/${currentOrganizationId}/reports/JourneyProgress`} className='btn btn--primary-menu mr-3' activeClassName='btn--active'>
                    Progress
                  </NavLink>
                  <NavLink to={`/organizations/${currentOrganizationId}/reports/JourneyResults`} className='btn btn--primary-menu mr-3' activeClassName='btn--active'>
                    Results
                  </NavLink>
                  <NavLink to={`/organizations/${currentOrganizationId}/reports/JourneySurvey`} className='btn btn--primary-menu mr-3' activeClassName='btn--active'>
                    Survey
                  </NavLink>
                  <NavLink to={`/organizations/${currentOrganizationId}/dashboard`} className='btn btn--primary-menu mr-3'>
                    Zoë Central
                  </NavLink>
                </div>
          )}
        </div>
        <div className='flex mt-10'>
          {parentTitle && (
            <>
              <Link to={parentLink} className='font-normal'>
                {parentTitle}
              </Link>
              <span className='mx-2'>{'/'}</span>
              <div className='header-title flex flex-wrap '>{title}</div>
            </>
          )}
        </div>
        </>
      )}
      {menuVisible && <Menu location={location} />}
    </div>
  );
};

export default withRouter(Header);
