export const refreshTokenInterceptor = logWarning => client => async (action, response) => {
  const HEADER_AUTHORIZATION = 'Authorization';
  const HEADER_TOKEN_EXPIRED = 'token-expired';
  const HEADER_TOKEN_RETRY = 'token-retry';
  const TRUE = 'true';

  const isTokenExpired = response.headers.get(HEADER_TOKEN_EXPIRED) === TRUE;

  const IsRequestATokenRetry =
    action.headers &&
    action.headers.hasOwnProperty(HEADER_TOKEN_RETRY) &&
    action.headers[HEADER_TOKEN_RETRY] === TRUE;

  if (isTokenExpired) {
    if (!IsRequestATokenRetry) {
      var newAccessToken = await onTokenExpiredHandler();
      var modified = {
        ...action,
        endpoint: action.originalEndpoint,
      };
      modified.headers[HEADER_TOKEN_RETRY] = TRUE;
      modified.headers[HEADER_AUTHORIZATION] = `Bearer ${newAccessToken.accessToken}`;

      response = await client.query(modified);
    } else if (IsRequestATokenRetry) {
      logWarning('Token refresh failed.');
      window.localStorage.removeItem('session');
      window.location.href = '/';
    }
  }
  return response;
};

/* TODO: Code smell, currently the circular dependency between
 * ClientContext and AuthContext requires this "hack" but maybe
 * in future there's an epiphany of a better approach.
 */
var onTokenExpiredHandler = null;
export const setOnTokenExpiredHandler = handler => (onTokenExpiredHandler = handler);
