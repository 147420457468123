import { useAuth } from '../hooks/useAuth';

export const PermissionActions = {
    Read: 'read',
    Update: 'update',
    Create: 'create'
};

export const PermissionModules = {
    Organizations: 'organizations',
    Groups: 'groups',
    Administrators: 'administrators',
    Participants: 'participants',
    Reports: 'reports',
    OrganizationAdministrators: 'organizationAdministrators'
}

function HasPermissions(permissionModule, permissionAction) {
    const { user } = useAuth();
    if(!user)
        return false;
    const { permissions } = user;
    if (!permissionModule || !permissionAction || !permissions) {
        return false;
    }
    return Boolean(permissions[permissionModule]?.includes?.(permissionAction));
};

export const useHasPermission = (permissionModule, permissionAction) => HasPermissions(permissionModule, permissionAction);
export const canReadOrganizations = () => HasPermissions(PermissionModules.Organizations, PermissionActions.Read);
export const canReadAdministrators = () => HasPermissions(PermissionModules.Administrators, PermissionActions.Read);
export const canReadOrgAdministrators = () => HasPermissions(PermissionModules.OrganizationAdministrators, PermissionActions.Read);
export const canReadParticipants = () => HasPermissions(PermissionModules.Participants, PermissionActions.Read);



