import classNames from 'classnames';
import React from 'react';

const CloseButton = ({ onToggle }) => {
  return (
    <button
      className={classNames('px-2 py-3 absolute top-0 right-0')}
      onClick={onToggle}
      height='14'
      width='14'
    >
      <svg
        height='14'
        width='14'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 14 14'
        fill='black'
      >
        <g id='Layer_2' data-name='Layer 2'>
          <g id='Layer_1-2' data-name='Layer 1'>
            <path d='M8.23,7l5.52-5.52a.89.89,0,0,0,0-1.23.89.89,0,0,0-1.23,0L7,5.77,1.48.25A.89.89,0,0,0,.25.25a.89.89,0,0,0,0,1.23L5.77,7,.25,12.52a.85.85,0,0,0-.25.61.88.88,0,0,0,.25.62.89.89,0,0,0,1.23,0L7,8.23l5.52,5.52a.85.85,0,0,0,.61.25.88.88,0,0,0,.62-.25.89.89,0,0,0,0-1.23Z' />
          </g>
        </g>
      </svg>
    </button>
  );
};

export default CloseButton;
