import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import CloseButton from './CloseButton';

const Menu = ({ history }) => {
  const [isOpened, setOpened] = useState(false);
  const { logout } = useAuth();
  const toggleOpen = () => setOpened(!isOpened);

  const doLogout = () => {
    logout();
    history.push('/login');
  };

  return (
    <MenuView opened={isOpened} onToggle={toggleOpen} onLogout={doLogout} />
  );
};

const MenuView = ({ opened, onToggle, onLogout }) => {
  const wrapperRef = useRef(null);

 
  const handleClickOutside = event => {
    if (
      opened &&
      wrapperRef &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      onToggle && onToggle();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div
      className={classNames('absolute z-50 top-0 right-0 rounded', {
        'pt-0 pr-0': opened
      })}
      ref={wrapperRef}
    >
      {opened ? (
        <MenuOpenedView onToggle={onToggle} onLogout={onLogout} />
      ) : (
        <button
          className={classNames('p-2 pt-1 pr-1 text-white menu-open-button')}
          onClick={onToggle}
          height='16'
          width='21'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 21 16'
            height='21'
            width='16'
          >
            <g id='Layer_2' data-name='Layer 2'>
              <g id='Layer_1-2' data-name='Layer 1'>
                <path d='M21,2H0V0H21Zm0,5H0V9H21Zm0,7H0v2H21Z' />
              </g>
            </g>
          </svg>
        </button>
      )}
    </div>
  );
};

const MenuOpenedView = ({ onToggle, onLogout }) => {

  const links = [
    {
      title: 'FAQs',
      pathname: 'https://www.exploremypurpose.com/faqs',
    },
    {
      title: 'Support',
      pathname: 'https://www.exploremypurpose.com/supportrequest',
    },
  ];


  return (
    <div className={classNames('menu bg-white relative shadow-xl rounded ')}>
      <CloseButton onToggle={onToggle} />
      <div className={classNames('flex flex-col items-start px-3 py-5')}>
           {links.map((linkItem, index) => {
          var item = <div key={index} ><MenuItemView linkItem={linkItem} onClick={onToggle} /></div>;
          return item
        })}
        <MenuItemButtonView label={'Log Out'} onClick={onLogout} />
      </div>
    </div>
  );
};



const MenuItemButtonView = ({ label, onClick }) => {
  return (
    <button
      className={classNames(
        'py-1 px-2 no-underline hover:font-bold menu--item'
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

  const MenuItemView = withRouter(({ location, linkItem, onClick }) => {
    const active =
      location.pathname === linkItem.pathname &&
      location?.state?.step === linkItem?.params?.step &&
      location?.state?.stage === linkItem?.params?.stage;
    return (
      <a class="py-1 px-2 no-underline menu--item" href={linkItem.pathname} target="_blank" rel="noreferrer noopener">
        {linkItem.title}
      </a>);
  });


export default withRouter(Menu);
export { MenuView };
