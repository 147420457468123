import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import ZoeLogo, { ZoeLogoType } from './ZoeLogo';

const Footer = ({ location, history }) => {
  const showFooter = pathname => {
    return (
      pathname === '/set-password' ||
      pathname === '/login' ||
      pathname === '/join' ||
      pathname === '/forgot-password' ||
      pathname === '/reset-password'
    );
  };
  return (
    //showFooter(location.pathname) && (
        <div className='flex flex-grow items-end pb-6'>
            <div className='flex flex-col'>
                <div className='flex w-full flex flex-col items-center'>
                    <Link to='/' className=' text-center p-4 block'>
                    <ZoeLogo type={ZoeLogoType.Color_NoTag} className='w-32 mx-auto' />
                    </Link>
                </div>
                <div className='flex w-full flex flex-col  text-center text-xs uppercase mx-auto '>
                    Powered by Via Vita
                </div>
            </div>
        </div>
    //)
  );
};

export default withRouter(Footer);