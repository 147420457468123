import React, { Fragment, Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { ClientContextProvider } from 'react-fetching-library';
import client from './api/Client';
import App from './App';
import Loading from './components/common/Loading';
import { AuthProvider } from './hooks/useAuth';
import { PageProvider } from './hooks/usePage';
import { SystemProvider } from './hooks/useSystem';
import { LoggerProvider, LoggerContext } from './hooks/useLogger';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import * as serviceWorker from './serviceWorker';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LoggerProvider>
    <LoggerContext.Consumer>
      {({ logApiClientError, logWarning }) => (
        <CookiesProvider>
          <ClientContextProvider client={client(logApiClientError, logWarning)}>
            <Suspense fallback={<Loading />}>
              <Fragment>
                <SystemProvider>
                  <AuthProvider>
                    <PageProvider>
                      <App />
                    </PageProvider>
                  </AuthProvider>
                </SystemProvider>
              </Fragment>
            </Suspense>
          </ClientContextProvider>
        </CookiesProvider>
      )}
    </LoggerContext.Consumer>
  </LoggerProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
