import React, { useContext, useState } from 'react';

export const PageContext = React.createContext();
export const usePage = () => useContext(PageContext);

export const PageProvider = ({ children }) => {
  const [title, setTitle] = useState('Zoe');
  const [parentTitle, setParentTitle] = useState(null);
  const [parentLink, setParentLink] = useState(null);
  const [backgroundClasses, setBackgroundClasses] = useState('');
  const [headerVisible, setHeaderVisible] = useState(true);
  const [menuVisible, setMenuVisible] = useState(true);
  const [paddingDisabled, setPaddingDisabled] = useState(false);
  const [currentOrganizationId, setCurrentOrganizationId] = useState(null);

  const setHeaderVisibleWithMenu = value => {
    setHeaderVisible(value);
    setMenuVisible(value);
  };

  const resetPage = () => {
    setTitle('Zoe');
    setParentLink(null);
    setParentTitle(null);
    setBackgroundClasses('');
    setHeaderVisible(true);
    setMenuVisible(true);
    setPaddingDisabled(false);
    setCurrentOrganizationId(null);
  };

  return (
    <PageContext.Provider
      value={{
        title,
        setTitle,
        parentTitle,
        setParentTitle,
        parentLink,
        setParentLink,
        backgroundClasses,
        setBackgroundClasses,
        headerVisible,
        setHeaderVisible: setHeaderVisibleWithMenu,
        paddingDisabled,
        setPaddingDisabled,
        menuVisible,
        setMenuVisible,
        resetPage,
        currentOrganizationId,
        setCurrentOrganizationId
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
