import React, { useContext, useEffect, useState } from 'react';
import { useClient } from 'react-fetching-library';
import Error from '../components/common/Error';

export const SystemContext = React.createContext();
export const useSystem = () => useContext(SystemContext);

const healthcheckQuery = accessToken => ({
  method: 'GET',
  endpoint: '/healthcheck'
});

export const SystemProvider = ({ children }) => {
  const [serverAvailable, setServerAvailable] = useState(true);
  const { query } = useClient();

  useEffect(() => {
    const checkServer = async () => {
      const { error, payload: response } = await query(healthcheckQuery());

      if (error || response !== 'ok') {
        setServerAvailable(false);
      }
    };
    checkServer();
  }, [query, setServerAvailable]);

  if (!serverAvailable) {
    children = (
      <Error
        title='Server not available'
        message='The Zoe server is not available. Please try again later.'
      />
    );
  }

  return <SystemContext.Provider>{children}</SystemContext.Provider>;
};
