import React from 'react';
import SecurityWrapper from '../security/SecurityWrapper';

const Error = ({ title, message, hideLogo, children }) => (
  <SecurityWrapper>
    <div className='flex-grow w-full flex flex-col p-6 '>
      <div className='flex-grow w-full flex flex-col items-start'>
        <h1 className='h1 w-full pb-6 text-center text-red'>
          {title || 'An error occured'}
        </h1>
        <div className='w-full'>
          <p className='text-center w-auto'>{message}</p>
        </div>
        {children && 
          <div className='w-full pt-8'>
            {children}
          </div>
        }
      </div>
    </div>
  </SecurityWrapper>
);

export default Error;
