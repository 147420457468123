import React, { useContext, useEffect, useState } from 'react';
import { useClient, useMutation } from 'react-fetching-library';
import { useLogger } from './useLogger';
import { setOnTokenExpiredHandler } from '../api/responseInterceptors/refreshTokenInterceptor';
import { SessionStateKeys } from '../constants';

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);

const loginAction = credentials => ({
  method: 'POST',
  endpoint: '/auth/login',
  body: {
    email: credentials.email,
    password: credentials.password,
    rememberMe: credentials.rememberMe,
    adminPortal: true,
  },
});

const exchangeRefreshTokenAction = ({ accessToken, refreshToken }) => ({
  method: 'POST',
  endpoint: '/auth/refreshtoken',
  body: {
    accessToken: accessToken,
    refreshToken: refreshToken,
  },
});

const profileQuery = accessToken => ({
  method: 'GET',
  endpoint: '/users/profile',
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

const authenticatedQuery = accessToken => ({
  method: 'GET',
  endpoint: '/users/authenticated',
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

export const AuthProvider = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [user, setUser] = useState();
  const [refreshToken, setRefreshToken] = useState();
  const [accessToken, setAccessToken] = useState();

  const { mutate } = useMutation(loginAction);
  const { mutate: exchangeRefreshTokenMutate } = useMutation(exchangeRefreshTokenAction);
  const { query } = useClient();
  const { logError } = useLogger();

  setOnTokenExpiredHandler(async () => {
    return await exchangeRefreshToken();
  });

  const setLocalStorageItemIfNotNull = (key, val) => {
    var s = null;
    if (val !== null && val !== undefined) {
      s = JSON.stringify(val);
    }
    window.localStorage.setItem(key, s);
  };

  useEffect(() => {
    accessToken !== undefined &&
      setLocalStorageItemIfNotNull(SessionStateKeys.AccessToken, accessToken);
  }, [accessToken]);

  useEffect(() => {
    refreshToken !== undefined &&
      setLocalStorageItemIfNotNull(SessionStateKeys.RefreshToken, refreshToken);
  }, [refreshToken]);

  useEffect(() => {
    user !== undefined && setLocalStorageItemIfNotNull(SessionStateKeys.User, user);
  }, [user]);

  useEffect(() => {
    const isAuthenticated =
      user !== null &&
      accessToken !== null &&
      refreshToken !== null &&
      user !== undefined &&
      accessToken !== undefined &&
      refreshToken !== undefined;
    setIsAuthenticated(isAuthenticated);
  }, [user, accessToken, refreshToken]);

  const parseIfNotNull = o => {
    if (o !== null && o !== undefined && o.toString() !== 'null') {
      return JSON.parse(o);
    }
    return null;
  };

  const loadFromStorage = () => {
    const session = {
      accessToken: parseIfNotNull(window.localStorage.getItem(SessionStateKeys.AccessToken)),
      refreshToken: parseIfNotNull(window.localStorage.getItem(SessionStateKeys.RefreshToken)),
      user: parseIfNotNull(window.localStorage.getItem(SessionStateKeys.User)),
    };
    setAccessToken(session.accessToken);
    setRefreshToken(session.refreshToken);
    setUser(session.user);
    return session;
  };

  const testIfUserIsAuthenticated = async session => {
    const { error: authenticatedError, payload: authenticatedResponse } = await query(
      authenticatedQuery(session && session.accessToken)
    );

    if (!authenticatedError && authenticatedResponse) {
      refreshProfile(session.accessToken);
    } else {
      logout();
    }
  };

  const refreshProfile = async accessToken => {
    const { error: profileError, payload: profileResponse } = await query(
      profileQuery(accessToken)
    );

    if (profileError) {
      logError('Error retrieving profile. Logging out. Error:', profileError);
      logout();
    }

    setUser(profileResponse);
    setReady(true);
  };

  useEffect(() => {
    const initAuth = async () => {
      const session = loadFromStorage();

      if (session.accessToken) {
        testIfUserIsAuthenticated(session);
      } else {
        setReady(true);
      }
    };

    initAuth();
  }, []);

  const login = async (email, password, rememberMe) => {
    const { error: loginError, payload: loginResponse } = await mutate({
      email,
      password,
      rememberMe,
    });

    if (!loginError) {
      setUser(user);
      setAccessToken(loginResponse.accessToken.token);
      setRefreshToken(loginResponse.refreshToken.token);
      refreshProfile(loginResponse.accessToken.token);
    }

    setReady(true);
    return loginResponse;
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    setAccessToken(null);
    window.localStorage.removeItem('session');
    setReady(true);
  };

  const updateUser = userDelta => {
    var newUser = { ...user, ...userDelta };
    setUser(newUser);
  };

  const exchangeRefreshToken = async () => {
    const {
      error: exchangeRefreshTokenError,
      payload: exchangeRefreshTokenResponse,
    } = await exchangeRefreshTokenMutate({
      accessToken,
      refreshToken,
    });

    if (
      exchangeRefreshTokenError ||
      (exchangeRefreshTokenResponse && !exchangeRefreshTokenResponse.success)
    ) {
      logout();
    } else {
      setAccessToken(exchangeRefreshTokenResponse.accessToken.token);
      setRefreshToken(exchangeRefreshTokenResponse.refreshToken.token);
      return { accessToken: exchangeRefreshTokenResponse.accessToken.token };
    }
  };

  const getApiType = () =>{
    return user? (user.isSuperAdmin? 'superadmin': (user.isAdmin? 'admin': '')): '';
  }

  return (
    <AuthContext.Provider
      value={{
        ready,
        isAuthenticated,
        accessToken,
        user,
        updateUser,
        login,
        logout,
        exchangeRefreshToken,
        getApiType,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
