import classNames from 'classnames';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { usePage } from '../hooks/usePage';
import Header from './common/Header';
import ZoeLogo, { ZoeLogoType } from './common/ZoeLogo';
import Footer from './common/Footer';

export default function Layout({ children }) {
  const { backgroundClasses, paddingDisabled } = usePage();
  const { menuVisible } = usePage();
  const { isAuthenticated, user } = useAuth();

  const canViewStudents =
    isAuthenticated && (user.isCounselor || user.isAdmin || user.isSuperAdmin);

  const canViewFellowTraveler =
    isAuthenticated && (user.isAdmin || user.isSuperAdmin);

  // const canViewCounselors = isAuthenticated && user.isSuperAdmin;

  const canViewAdmins = isAuthenticated && user.isSuperAdmin;

  return (
    <>
      <div
        className={classNames(
          'layout w-full flex flex-grow',
          backgroundClasses
        )}
      >
        <div className={classNames('w-full flex flex-col p-3')}>
          <Header />
          <div className="mt-10">
            {children}
          </div>
          <Footer/>
        </div>
      </div>
    </>
  );
}

const LeftNavItem = withRouter(({ location, title, to }) => {
  const active = location.pathname === to;
  return (
    <div
      className={classNames('px-5 py-3 w-full cursor-pointer', {
        'leftnav--active': active
      })}
    >
      <Link
        to={to}
        className={'cursor-pointer font-normal block h3 h3--white no-underline'}
      >
        {title}
      </Link>
    </div>
  );
});
