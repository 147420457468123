import React from 'react';
import Loadable from 'react-loadable';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import Loading from './components/common/Loading';
import Layout from './components/Layout';
import { useAuth } from './hooks/useAuth';
import AuthorizedRoute from './routing/AuthorizedRoute';
import { PermissionActions, PermissionModules } from './hooks/usePermissions';

function App() {
  const { user, ready } = useAuth();

  if (!ready) {
    return <Loading />;
  }
  console.log(user)

  return (
    <Router>
      <Layout>
        <Switch>
          <Redirect from='/' exact to={user ?(user.isSuperAdmin?'/organizations': (user.isAdmin?`/organizations/${user.organizationId}/dashboard`: '/access-denied')):'/login'} />
          <AuthorizedRoute path='/students' exact component={AsyncStudents} permissionModule={PermissionModules.Participants}  permissionAction={PermissionActions.Read}/>
          <AuthorizedRoute path='/admins' exact component={AsyncAdmins} permissionModule={PermissionModules.Administrators}  permissionAction={PermissionActions.Read}/>
          <AuthorizedRoute path='/admins/new' exact component={AsyncNewAdmin} permissionModule={PermissionModules.Administrators}  permissionAction={PermissionActions.Create}/>
          <AuthorizedRoute path='/admins/:id' exact component={AsyncEditAdmin} permissionModule={PermissionModules.Administrators}  permissionAction={PermissionActions.Update}   />
          <AuthorizedRoute path='/organizations' exact component={AsyncOrganizations} permissionModule={PermissionModules.Organizations} permissionAction={PermissionActions.Read} />
          <AuthorizedRoute path='/organizations/new' exact component={AsyncNewOrganization} permissionModule={PermissionModules.Organizations} permissionAction={PermissionActions.Create} />
          <AuthorizedRoute path='/organization-types' exact component={AsyncOrganizationTypes} permissionModule={PermissionModules.Organizations} permissionAction={PermissionActions.Create} />
          <AuthorizedRoute path='/organization-types/new' exact component={AsyncNewOrganizationType} permissionModule={PermissionModules.Organizations} permissionAction={PermissionActions.Create} />
          <AuthorizedRoute path='/organizations/:id' exact component={AsyncEditOrganization} permissionModule={PermissionModules.Organizations} permissionAction={PermissionActions.Update} />
          <AuthorizedRoute path='/organizations/:id/groups' exact component={AsyncGroups} permissionModule={PermissionModules.Groups} permissionAction={PermissionActions.Read} />
          <AuthorizedRoute path='/organizations/:id/groups/new' exact component={AsyncNewGroup} permissionModule={PermissionModules.Groups} permissionAction={PermissionActions.Create} />
          <AuthorizedRoute path='/organizations/:id/groups/:groupid' exact component={AsyncEditOrganizationGroup} permissionModule={PermissionModules.Groups} permissionAction={PermissionActions.Read} />
          <AuthorizedRoute path='/organizations/:id/dashboard' exact component={AsyncOrganizationDashboard} permissionModule={PermissionModules.Participants} permissionAction={PermissionActions.Read} />
          <AuthorizedRoute path='/organizations/:id/admins' exact component={AsyncOrganizationAdmins} permissionModule={PermissionModules.OrganizationAdministrators} permissionAction={PermissionActions.Read} />
          <AuthorizedRoute path='/organizations/:id/admins/new' exact component={AsyncNewOrganizationAdmin} permissionModule={PermissionModules.OrganizationAdministrators}  permissionAction={PermissionActions.Create}/>
          <AuthorizedRoute path='/organizations/:id/admins/:adminid' exact component={AsyncEditOrganizationAdmin} permissionModule={PermissionModules.OrganizationAdministrators}  permissionAction={PermissionActions.Update}   />
          
          <AuthorizedRoute path='/organizations/:id/participants' exact component={AsyncOrganizationParticipants} permissionModule={PermissionModules.Participants} permissionAction={PermissionActions.Read} />
          <AuthorizedRoute path='/organizations/:id/participants/new' exact component={AsyncNewOrganizationParticipant} permissionModule={PermissionModules.Participants}  permissionAction={PermissionActions.Create}/>
          <AuthorizedRoute path='/organizations/:id/participants/:participantid' exact component={AsyncEditOrganizationParticipant} permissionModule={PermissionModules.Participants}  permissionAction={PermissionActions.Update}  />

          
          <AuthorizedRoute path='/organizations/:id/reports/journeyprogress' exact component={AsyncOrganizationJourneyProgress} permissionModule={PermissionModules.Reports} permissionAction={PermissionActions.Read} />
          <AuthorizedRoute path='/organizations/:id/reports/journeyresults' exact component={AsyncOrganizationJourneyResults} permissionModule={PermissionModules.Reports} permissionAction={PermissionActions.Read} />
          <AuthorizedRoute path='/organizations/:id/reports/journeysurvey' exact component={AsyncOrganizationJourneySurvey} permissionModule={PermissionModules.Reports} permissionAction={PermissionActions.Read} />
          
          <Route path='/access-denied' component={AsyncAccessDenied} />
          <Route path='/join' component={AsyncJoin} />
          <Route path='/set-password' component={AsyncSetPassword} />
          <Route path='/reset-password' component={AsyncResetPassword} />
          <Route path='/forgot-password' component={AsyncForgotPassword} />
          <Route path='/login' component={AsyncLogin} />
          <Route path='/styles' component={AsyncStyles} />
        </Switch>
      </Layout>
    </Router>
  );
}

const AsyncStudents = Loadable({
  loader: () => import('./pages/Students'),
  loading: Loading
});

const AsyncAdmins = Loadable({
  loader: () => import('./pages/admins/Index'),
  loading: Loading
});

const AsyncEditAdmin = Loadable({
  loader: () => import('./pages/admins/_admin_id/Index'),
  loading: Loading
});

const AsyncNewAdmin = Loadable({
  loader: () => import('./pages/admins/New'),
  loading: Loading
});

const AsyncProfile = Loadable({
  loader: () => import('./pages/Profile'),
  loading: Loading
});

const AsyncPreferences = Loadable({
  loader: () => import('./pages/Preferences'),
  loading: Loading
});

const AsyncJoin = Loadable({
  loader: () => import('./pages/Join'),
  loading: Loading
});

const AsyncAccessDenied = Loadable({
  loader: () => import('./pages/AccessDenied'),
  loading: Loading
});

const AsyncSetPassword = Loadable({
  loader: () => import('./pages/SetPassword'),
  loading: Loading
});

const AsyncResetPassword = Loadable({
  loader: () => import('./pages/ResetPassword'),
  loading: Loading
});

const AsyncForgotPassword = Loadable({
  loader: () => import('./pages/ForgotPassword'),
  loading: Loading
});

const AsyncLogin = Loadable({
  loader: () => import('./pages/Login'),
  loading: Loading
});

//This is temporary just to view global styles
const AsyncStyles = Loadable({
  loader: () => import('./pages/Styles'),
  loading: Loading
});

const AsyncOrganizations = Loadable({
  loader: () => import('./pages/organizations/Index'),
  loading: Loading
});

const AsyncNewOrganization = Loadable({
  loader: () => import('./pages/organizations/New'),
  loading: Loading
});

const AsyncOrganizationTypes = Loadable({
  loader: () => import('./pages/organizations/organization_types/Index'),
  loading: Loading
});

const AsyncNewOrganizationType = Loadable({
  loader: () => import('./pages/organizations/organization_types/New'),
  loading: Loading
});

const AsyncEditOrganization = Loadable({
  loader: () => import('./pages/organizations/_organization_id/Index'),
  loading: Loading
});

const AsyncGroups = Loadable({
  loader: () => import('./pages/organizations/_organization_id/groups/Index'),
  loading: Loading
});

const AsyncNewGroup = Loadable({
  loader: () => import('./pages/organizations/_organization_id/groups/New'),
  loading: Loading
});

const AsyncEditOrganizationGroup = Loadable({
  loader: () => import('./pages/organizations/_organization_id/groups/_group_id/Index'),
  loading: Loading
});

const AsyncOrganizationDashboard = Loadable({
  loader: () => import('./pages/organizations/_organization_id/dashboard/Index'),
  loading: Loading
})

const AsyncOrganizationAdmins = Loadable({
  loader: () => import('./pages/organizations/_organization_id/admins/Index'),
  loading: Loading
}); 

const AsyncNewOrganizationAdmin = Loadable({
  loader: () => import('./pages/organizations/_organization_id/admins/New'),
  loading: Loading
});

const AsyncEditOrganizationAdmin = Loadable({
  loader: () => import('./pages/organizations/_organization_id/admins/_admin_id/Index'),
  loading: Loading
});

const AsyncOrganizationParticipants = Loadable({
  loader: () => import('./pages/organizations/_organization_id/participants/Index'),
  loading: Loading
});

const AsyncNewOrganizationParticipant = Loadable({
  loader: () => import('./pages/organizations/_organization_id/participants/New'),
  loading: Loading
});

const AsyncEditOrganizationParticipant = Loadable({
  loader: () => import('./pages/organizations/_organization_id/participants/_participant_id/Index'),
  loading: Loading
});

const AsyncOrganizationJourneyProgress = Loadable({
  loader: () => import('./pages/organizations/_organization_id/reports/JourneyProgress'),
  loading: Loading
});

const AsyncOrganizationJourneyResults = Loadable({
  loader: () => import('./pages/organizations/_organization_id/reports/JourneyResults'),
  loading: Loading
});

const AsyncOrganizationJourneySurvey = Loadable({
  loader: () => import('./pages/organizations/_organization_id/reports/JourneySurvey'),
  loading: Loading
});

export default App;
